export enum UserActionTypes {
  API_RESPONSE_SUCCESS = "@user/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@userList/API_RESPONSE_ERROR",
  
  USER_REQUEST = 'USER_REQUEST',
  USER_SUCCESS = 'USER_SUCCESS',
  USER_FAILURE = 'USER_FAILURE',
  
  USER_LIST_REQUEST = 'USER_LIST_REQUEST',
  USER_LIST_SUCCESS = 'USER_LIST_SUCCESS',
  USER_LIST_FAILURE = 'USER_LIST_FAILURE',
}
