// constants
import { VisitorActionTypes } from "./constants";
import { VisitorData, VisitorPut } from "./types";

export interface VisitorActionType {
  type:
  | VisitorActionTypes.API_RESPONSE_SUCCESS
  | VisitorActionTypes.API_RESPONSE_ERROR
  | VisitorActionTypes.VISITOR_REQUEST
  | VisitorActionTypes.VISITOR_SUCCESS
  | VisitorActionTypes.VISITOR_FAILURE
  | VisitorActionTypes.VISITOR_LIST_REQUEST
  | VisitorActionTypes.VISITOR_LIST_SUCCESS
  | VisitorActionTypes.VISITOR_LIST_FAILURE
  | VisitorActionTypes.VISITOR_EDIT_REQUEST
  | VisitorActionTypes.VISITOR_EDIT_SUCCESS
  | VisitorActionTypes.VISITOR_EDIT_FAILURE
  | VisitorActionTypes.VISITOR_UPDATE_REQUEST
  | VisitorActionTypes.VISITOR_UPDATE_SUCCESS
  | VisitorActionTypes.VISITOR_UPDATE_FAILURE
  | VisitorActionTypes.VISITOR_PUT_REQUEST
  | VisitorActionTypes.VISITOR_PUT_SUCCESS
  | VisitorActionTypes.VISITOR_PUT_FAILURE;

  payload: {} | string;
}

export const visitorApiResponseSuccess = (
  actionType: string,
  data: VisitorData | {}
): VisitorActionType => ({
  type: VisitorActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const visitorApiResponseError = (
  actionType: string,
  error: string
): VisitorActionType => ({
  type: VisitorActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export const visitorsListRequest = (): VisitorActionType => ({
  type: VisitorActionTypes.VISITOR_LIST_REQUEST,
  payload: {},
});

export const  visitorsListSuccess = (visitors: []) => {
  return {
    type: VisitorActionTypes.VISITOR_LIST_SUCCESS,
    payload: {
      visitors,
    },
  };
}


export const visitorRequest = (
  visitor: VisitorData
  ): VisitorActionType => ({
    type: VisitorActionTypes.VISITOR_REQUEST,
    payload: { visitor },
  });

  export const  visitorSuccess = (actionType: string, visitor: VisitorData) => {
    return {
      type: VisitorActionTypes.VISITOR_SUCCESS,
      payload: {
        actionType,
        visitor,
      },
    };
  }
export const visitorEditRequest = (
  visitorEdit: VisitorData
  ): VisitorActionType => ({
    type: VisitorActionTypes.VISITOR_EDIT_REQUEST,
    payload: { visitorEdit },
  });

  export const  visitorEditSuccess = (actionType: string, visitorEdit: VisitorData) => {
    return {
      type: VisitorActionTypes.VISITOR_EDIT_SUCCESS,
      payload: {
        actionType,
        visitorEdit,
      },
    };
  }
export const visitorUpdateRequest = (
  visitor: VisitorData
  ): VisitorActionType => ({
    type: VisitorActionTypes.VISITOR_UPDATE_REQUEST,
    payload: { visitor },
  });

  export const  visitorUpdateSuccess = (actionType: string, visitor: VisitorData) => {
    return {
      type: VisitorActionTypes.VISITOR_UPDATE_SUCCESS,
      payload: {
        actionType,
        visitor,
      },
    };
  }
export const visitorPutRequest = (
  visitor: VisitorPut
  ): VisitorActionType => ({
    type: VisitorActionTypes.VISITOR_PUT_REQUEST,
    payload: { visitor },
  });

  export const  visitorPutSuccess = (actionType: string, visitor: VisitorPut) => {
    return {
      type: VisitorActionTypes.VISITOR_PUT_SUCCESS,
      payload: {
        actionType,
        visitor,
      },
    };
  }