import { AxiosResponse } from "axios";
import { APICore } from "./apiCore";
import { CreateUser, VisitorCreator } from "./types";

const api = new APICore();

// account
const createUser = async (params: CreateUser) => {
  const baseUrl = "user";
  return api.create(`${baseUrl}`, params);
};

const createVisitor = async (params: VisitorCreator) => {
  const baseUrl = "visitors";
  return api.create(`${baseUrl}`, params);
};

const visitorEdit = async (id: string) => {
  const baseUrl = `visitors/${id}`;
  return api.create(`${baseUrl}`, {});
};

const usersList = async () => {
  const baseUrl = "user/users";
  const response = await api.get(`${baseUrl}`, {});
  return response;
};

const visitorsChurch = async () => {
  const baseUrl = "visitors";
  return api.get(`${baseUrl}`, {});
};

const visitorUpdate = async (params: VisitorCreator) => {
  const baseUrl = `visitors/${params.id}`;
  return api.updatePatch(`${baseUrl}`, params);
};
const visitorPut = async (params: VisitorCreator) => {
  const baseUrl = `visitors/${params.id}`;
  return api.update(`${baseUrl}`, params);
};

export {
  createUser,
  usersList,
  visitorsChurch,
  createVisitor,
  visitorEdit,
  visitorUpdate,
  visitorPut
};
