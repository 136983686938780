// apicore

// constants
import {  VisitorPublicActionTypes } from "./constants";
import { VisitorDataState, VisitorPublicData } from "./types";

const INIT_STATE: VisitorDataState = {
  visitor: {} as VisitorPublicData,
  loading: false,

};

interface VisitorPublicActionType {
  type:
  | VisitorPublicActionTypes.API_RESPONSE_SUCCESS
  | VisitorPublicActionTypes.API_RESPONSE_ERROR
  | VisitorPublicActionTypes.VISITOR_PUBLIC_REQUEST
  | VisitorPublicActionTypes.VISITOR_PUBLIC_SUCCESS
  | VisitorPublicActionTypes.VISITOR_PUBLIC_FAILURE;
  payload: {
    actionType?: string;
    data?: VisitorPublicData | {};
    error?: string;
    loading: false;
  };
}

interface State {
  visitor?: VisitorPublicData | {}; 
  loading?: boolean;

}

const AddVisitorsPublic = (state: State = INIT_STATE, action: VisitorPublicActionType): any => {
  switch (action.type) {
    case VisitorPublicActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case VisitorPublicActionTypes.VISITOR_PUBLIC_REQUEST: {
          return {
            ...state,
            loading: true,
          };
        }
        case VisitorPublicActionTypes.VISITOR_PUBLIC_SUCCESS: {
          return {
            ...state,
            visitor: action.payload.data,
            loading: true,
          };
        }
        default:
          return { ...state };
      }

      case VisitorPublicActionTypes.API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case VisitorPublicActionTypes.VISITOR_PUBLIC_FAILURE: {
            return {
              ...state,
              error: action.payload.error,
              loading: false,
            };
          }
 
          default:
            return { ...state };
        }
    default:
      return { ...state };
  }
};

export default AddVisitorsPublic;
