import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { createUser } from "../../helpers/";

// actions
import {
visitorPublicApiResponseError,
visitorPublicRequest,
visitorPublicSuccess
} from "./actions";


// constants
import { VisitorPublicActionTypes } from "./constants";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { addVisitors } from "../../helpers/api/auth";




function* create({ payload }: any): SagaIterator {
  try {
    
    const { visitor } = payload;
    console.log('payload', visitor)

    const response: AxiosResponse<any> = yield call(addVisitors, visitor);
    const { data } = response;
    if (data) {
      toast.success("Cadastro realizado com sucesso!");
      yield put(visitorPublicSuccess(VisitorPublicActionTypes.VISITOR_PUBLIC_SUCCESS, data));
    }
    window.location.pathname = '/success';   
    
  } catch (error: any) {
    console.log('payload', error)

    toast.error('Oops! Houve um erro ao cadastrar! Tente novamente!')
    yield put(visitorPublicApiResponseError(VisitorPublicActionTypes.VISITOR_PUBLIC_FAILURE, error));
  }
}

export function* watchVisitorPublicCreate() {
  yield takeEvery(VisitorPublicActionTypes.VISITOR_PUBLIC_REQUEST, create);
}

function* visitorPublicSaga() {
  yield all([fork(watchVisitorPublicCreate)]);
}

export default visitorPublicSaga;
