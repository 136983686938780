import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import User from "./user/reducers";
import Visitor from "./visitors/reducers";
import AddVisitorsPublic from "./addVisitorsPublic/reducers";

export default combineReducers({
  Auth,
  Layout,
  User,
  Visitor,
  AddVisitorsPublic
});
