import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  createUser,
  createVisitor,
  visitorPut,
  visitorUpdate,
  visitorsChurch,
} from "../../helpers/api/api";

// actions
import {
  visitorApiResponseError,
  visitorApiResponseSuccess,
  visitorEditSuccess,
  visitorRequest,
  visitorSuccess,
  visitorsListRequest,
  visitorsListSuccess,
} from "./actions";

// constants
import { VisitorActionTypes } from "./constants";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface VisitorData {
  payload: {
    visitor: {
      id?: string;
      name: string;
      lastName: string;
      address: string;
      phone: string;
      isWhatsapp: boolean;
    };
  };
  type: string;
}

function* visitors() {
  try {
    const response: AxiosResponse<any> = yield call(visitorsChurch);
    const { data } = response;
    yield put(
      visitorApiResponseSuccess(VisitorActionTypes.VISITOR_LIST_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(
      visitorApiResponseError(VisitorActionTypes.VISITOR_LIST_FAILURE, error)
    );
  }
}

function* create({ payload }: any) {
  try {
    const { visitor } = payload;

    const response: AxiosResponse<any> = yield call(createVisitor, visitor);
    const { data } = response;
    if (data) {
      toast.success("Novo visitante cadastrado com sucesso!");
      yield put(visitorsListRequest());
      yield put(visitorSuccess(VisitorActionTypes.VISITOR_SUCCESS, data));
    }
  } catch (error: any) {
    toast.error("Oops! Houve um erro ao cadastrar! Tente novamente!");
    yield put(
      visitorApiResponseError(VisitorActionTypes.VISITOR_FAILURE, error)
    );
  }
}
function* edit({ payload }: any) {
  try {
    const { visitor } = payload;
    yield put(
      visitorEditSuccess(VisitorActionTypes.VISITOR_EDIT_SUCCESS, visitor)
    );
  } catch (error: any) {
    toast.error("Oops! Houve um erro ao editar os dados! Tente novamente!");
    yield put(
      visitorApiResponseError(VisitorActionTypes.VISITOR_EDIT_FAILURE, error)
    );
  }
}
function* update({ payload }: any) {
  try {
    const { visitor } = payload;

    const response: AxiosResponse<any> = yield call(visitorUpdate, visitor);
    const { data } = response;
    if (data) {
      toast.success("Visitante atualizado com sucesso!");
      yield put(visitorsListRequest());
      yield put(visitorSuccess(VisitorActionTypes.VISITOR_SUCCESS, data));
    }
  } catch (error: any) {
    toast.error("Oops! Houve um erro ao atualizar o cadastro! Tente novamente!");
    yield put(
      visitorApiResponseError(VisitorActionTypes.VISITOR_FAILURE, error)
    );
  }
}

function* putVisitor({ payload }: any) {
  try {
    const { visitor } = payload;
    console.log('payload', visitor)

    const response: AxiosResponse<any> = yield call(visitorPut, visitor);
    const { data } = response;
    if (data) {
      toast.success("Visitante atualizado com sucesso!");
      yield put(visitorsListRequest());
      yield put(visitorSuccess(VisitorActionTypes.VISITOR_PUT_SUCCESS, data));
    }
  } catch (error: any) {
    console.log('ereror', error)
    toast.error("Oops! Houve um erro ao atualizar o cadastro! Tente novamente!");
    yield put(
      visitorApiResponseError(VisitorActionTypes.VISITOR_PUT_FAILURE, error)
    );
  }
}

export function* watchVisitorsList() {
  yield takeEvery(VisitorActionTypes.VISITOR_LIST_REQUEST, visitors);
}

export function* watchVisitorCreate() {
  yield takeEvery(VisitorActionTypes.VISITOR_REQUEST, create);
}

export function* watchVisitorEdit() {
  yield takeEvery(VisitorActionTypes.VISITOR_EDIT_REQUEST, edit);
}

export function* watchVisitorUpdate() {
  yield takeEvery(VisitorActionTypes.VISITOR_UPDATE_REQUEST, update);
}

export function* watchVisitorPut() {
  yield takeEvery(VisitorActionTypes.VISITOR_PUT_REQUEST, putVisitor);
}

function* visitorSaga() {
  yield all([
    fork(watchVisitorsList),
    fork(watchVisitorCreate),
    fork(watchVisitorEdit),
    fork(watchVisitorUpdate),
    fork(watchVisitorPut),
  ]);
}

export default visitorSaga;
