import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import userSaga from "./user/saga";
import visitorSaga from "./visitors/saga";
import addVisitorsPublic from "./addVisitorsPublic/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    userSaga(),
    visitorSaga(),
    addVisitorsPublic(),
  ]);
}
