export enum VisitorActionTypes {
  API_RESPONSE_SUCCESS = "@visitor/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@visitor/API_RESPONSE_ERROR",
  
  VISITOR_REQUEST = 'VISITOR_REQUEST',
  VISITOR_SUCCESS = 'VISITOR_SUCCESS',
  VISITOR_FAILURE = 'VISITOR_FAILURE',
  
  VISITOR_LIST_REQUEST = 'VISITOR_LIST_REQUEST',
  VISITOR_LIST_SUCCESS = 'VISITOR_LIST_SUCCESS',
  VISITOR_LIST_FAILURE = 'VISITOR_LIST_FAILURE',

  VISITOR_EDIT_REQUEST = 'VISITOR_EDIT_REQUEST',
  VISITOR_EDIT_SUCCESS = 'VISITOR_EDIT_SUCCESS',
  VISITOR_EDIT_FAILURE = 'VISITOR_EDIT_FAILURE',

  VISITOR_UPDATE_REQUEST = 'VISITOR_UPDATE_REQUEST',
  VISITOR_UPDATE_SUCCESS = 'VISITOR_UPDATE_SUCCESS',
  VISITOR_UPDATE_FAILURE = 'VISITOR_UPDATE_FAILURE',

  VISITOR_PUT_REQUEST = 'VISITOR_PUT_REQUEST',
  VISITOR_PUT_SUCCESS = 'VISITOR_PUT_SUCCESS',
  VISITOR_PUT_FAILURE = 'VISITOR_PUT_FAILURE',
}
