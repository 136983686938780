// apicore

// constants
import { VisitorActionTypes } from "./constants";
import { VisitorData, VisitorDataState } from "./types";

const INIT_STATE: VisitorDataState = {
  visitor: {} as VisitorData,
  visitors: [],
  visitorEdit: {} as VisitorData,
  visitorUpdate: {} as VisitorData,
  loading: false,
};

interface VisitorActionType {
  type:
    | VisitorActionTypes.API_RESPONSE_SUCCESS
    | VisitorActionTypes.API_RESPONSE_ERROR
    | VisitorActionTypes.VISITOR_REQUEST
    | VisitorActionTypes.VISITOR_SUCCESS
    | VisitorActionTypes.VISITOR_FAILURE
    | VisitorActionTypes.VISITOR_LIST_REQUEST
    | VisitorActionTypes.VISITOR_LIST_SUCCESS
    | VisitorActionTypes.VISITOR_LIST_FAILURE
    | VisitorActionTypes.VISITOR_EDIT_REQUEST
    | VisitorActionTypes.VISITOR_EDIT_SUCCESS
    | VisitorActionTypes.VISITOR_EDIT_FAILURE
    | VisitorActionTypes.VISITOR_UPDATE_REQUEST
    | VisitorActionTypes.VISITOR_UPDATE_SUCCESS
    | VisitorActionTypes.VISITOR_UPDATE_FAILURE
    | VisitorActionTypes.VISITOR_PUT_REQUEST
    | VisitorActionTypes.VISITOR_PUT_SUCCESS
    | VisitorActionTypes.VISITOR_PUT_FAILURE;
  payload: {
    actionType?: string;
    data?: VisitorData | {};
    visitorEdit?: VisitorData | {};
    error?: string;
    loading: false;
  };
}

interface State {
  visitor?: VisitorData | {};
  visitors?: VisitorData[] | [];
  visitorEdit?: VisitorData | {};
  visitorUpdate?: VisitorData | {};
  loading?: boolean;
}

const Visitor = (state: State = INIT_STATE, action: VisitorActionType): any => {
  switch (action.type) {
    case VisitorActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case VisitorActionTypes.VISITOR_REQUEST: {
          return {
            ...state,
            loading: true,
          };
        }
        case VisitorActionTypes.VISITOR_SUCCESS: {
          return {
            ...state,
            visitor: action.payload.data,
            loading: false,
          };
        }
        case VisitorActionTypes.VISITOR_LIST_SUCCESS: {
          return {
            ...state,
            visitors: action.payload.data,
            loading: false,
          };
        }

        case VisitorActionTypes.VISITOR_UPDATE_REQUEST: {
          return {
            ...state,
            visitorUpdate: action.payload.data,
            loading: true,
          };
        }
        case VisitorActionTypes.VISITOR_UPDATE_SUCCESS: {
          return {
            ...state,
            visitorUpdate: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case VisitorActionTypes.VISITOR_EDIT_REQUEST: {
      console.log("VISITOR_EDIT_REQUEST", action.payload);
      return {
        ...state,
        visitorEdit: action.payload.visitorEdit,
        loading: true,
      };
    }
    case VisitorActionTypes.VISITOR_EDIT_SUCCESS: {
      return {
        ...state,
        visitoredit: action.payload.data,
        loading: false,
      };
    }
    case VisitorActionTypes.VISITOR_PUT_REQUEST: {
      return {
        ...state,
        visitorUpdate: action.payload.data,
        loading: true,
      };
    }
    case VisitorActionTypes.VISITOR_PUT_SUCCESS: {
      return {
        ...state,
        visitorUpdate: action.payload.data,
        loading: false,
      };
    }
    case VisitorActionTypes.VISITOR_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VisitorActionTypes.VISITOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VisitorActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case VisitorActionTypes.VISITOR_FAILURE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case VisitorActionTypes.VISITOR_LIST_FAILURE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default Visitor;
