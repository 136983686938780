import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { createUser, usersList } from "../../helpers/";

// actions
import {
  userApiResponseError,
  userApiResponseSuccess,
  userSuccess,
  usersListRequest
} from "./actions";


// constants
import { UserActionTypes } from "./constants";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface UserData {
  payload: {
    user: {
      id?: string;
      email: string;
      password: string;
      name: string;
      lastName: string;
      role: "super" | "supervisor" | "supervisorLeader" | "leader";
      status: "active" | "inactive";
    };
  };
  type: string;
}

 function* users() {
  try {
    const response: AxiosResponse<any> = yield call(usersList);
    const { data } = response;
    yield put(
      userApiResponseSuccess(UserActionTypes.USER_LIST_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(userApiResponseSuccess(UserActionTypes.USER_LIST_FAILURE, error));
  }
}

function* create({ payload }: any) {
  try {
    const { user } = payload;

    const response: AxiosResponse<any> = yield call(createUser, user);
    const { data } = response;
    if (data) {
      toast.success("Novo usuário cadastrado com sucesso!");
      yield put(usersListRequest());
    }

    yield put(userSuccess(UserActionTypes.USER_SUCCESS, data));
    
    
  } catch (error: any) {
    toast.error('Oops! Houve um erro ao cadastrar! Tente novamente!')
    yield put(userApiResponseError(UserActionTypes.USER_FAILURE, error));
  }
}

export function* watchUsersList() {
  yield takeEvery(UserActionTypes.USER_LIST_REQUEST, users);
}

export function* watchUserCreate() {
  yield takeEvery(UserActionTypes.USER_REQUEST, create);
}

function* userSaga() {
  yield all([fork(watchUsersList), fork(watchUserCreate)]);
}

export default userSaga;
