// constants
import { VisitorPublicActionTypes } from "./constants";
import {  VisitorPublicData} from "./types";

export interface VisitorPublicActionType {
  type:
  | VisitorPublicActionTypes.API_RESPONSE_SUCCESS
  | VisitorPublicActionTypes.API_RESPONSE_ERROR
  | VisitorPublicActionTypes.VISITOR_PUBLIC_REQUEST
  | VisitorPublicActionTypes.VISITOR_PUBLIC_SUCCESS
  | VisitorPublicActionTypes.VISITOR_PUBLIC_FAILURE;

  payload: {} | string;
}

export const visitorPublicApiResponseSuccess = (
  actionType: string,
  data: VisitorPublicData | {}
): VisitorPublicActionType => ({
  type: VisitorPublicActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const visitorPublicApiResponseError = (
  actionType: string,
  error: string
): VisitorPublicActionType => ({
  type: VisitorPublicActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export const visitorPublicRequest = (
  visitor: VisitorPublicData
  ): VisitorPublicActionType => ({
    type: VisitorPublicActionTypes.VISITOR_PUBLIC_REQUEST,
    payload: { visitor },
  });

  export const  visitorPublicSuccess = (actionType: string, visitor: VisitorPublicData) => {
    return {
      type: VisitorPublicActionTypes.VISITOR_PUBLIC_SUCCESS,
      payload: {
        actionType,
        visitor,
      },
    };
  }