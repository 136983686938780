import React from "react";

import AllRoutes from "./routes/Routes";
import 'react-toastify/dist/ReactToastify.css';

// Themes

// For Default import Default.scss
import "./assets/scss/Default.scss";
import { ToastContainer } from "react-toastify";
// For Dark import Default-Dark.scss
// import './assets/scss/Default-Dark.scss';

// For Saas import Saas.scss
// import './assets/scss/Saas.scss';
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

// For Material demo import Material.scss
// import './assets/scss/Material.scss';
// For Material dark demo import Material-Dark.scss
// import './assets/scss/Material-Dark.scss';

// For Corporate demo import Corporate.scss
// import './assets/scss/Corporate.scss';
// For Corporate dark demo import Corporate-Dark.scss
// import './assets/scss/Corporate-Dark.scss';

// configure fake backend

const App = () => {
  return (
    <>
      <React.Fragment>
      <ToastContainer />
        <AllRoutes />
      </React.Fragment>
    </>
  )
};

export default App;
